.progressBar{
	display: flex;
    align-items: center;
	position: relative;
}
.range__slider {
	-webkit-appearance: none;
	background-color: #535353;
	height: 4px;
	overflow: hidden;
	width: auto;
	border-radius: 2px;
}
.spanThumb{
	width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50px;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
}
.range__slider::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	height: 4px;
}
.range__slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	position: relative;
	z-index: 9;
	background: #b3b3b3;
	border-radius: 50%;
	box-shadow: -410px 0 0 408px #b3b3b3;
	cursor: pointer;
	height: 4px;
	width: 4px;
	border: 0;
}
.range__slider::-moz-range-thumb {
	background: #b3b3b3;
	border-radius: 50%;
	box-shadow: -410px 0 0 408px #b3b3b3;
	cursor: pointer;
	height: 4px;
	width: 4px;
	border: 0;
}
.range__slider::-moz-range-track {
	background-color: #535353;
}
.range__slider::-moz-range-progress {
	background-color: #b3b3b3;
	height: 4px;
}
.range__slider::-ms-fill-upper {
	background-color: #535353;
}
.range__slider::-ms-fill-lower {
	background-color: #b3b3b3;
}
.progressBar:hover .range__slider::-webkit-slider-thumb {
	background: #f96b06;
	box-shadow: -410px 0 0 408px #f96b06;
}
.progressBar:hover .range__slider::-moz-range-thumb {
	background: #f96b06;
	box-shadow: -410px 0 0 408px #f96b06;
}
.progressBar:hover span {
    background: #f96b06 !important;
    opacity: 1 !important;
}