.SearchPage{
    background-color: #121212;
    color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
}
.Search{
    padding: 0px 32px;
    padding-bottom: 32px;
    margin-bottom: 95px;
}
.SearchCardGrid{
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill,minmax(180px,1fr));
    padding-top: 16px;
}

@media screen and (max-width: 640px) {
    .Search{
        padding: 16px;
        margin-bottom: 180px;
        margin-top: 24px;
    }
    .SearchCardGrid{
        grid-gap: 16px;
        grid-template-columns: 1fr 1fr;
    }
}


.SongBtn{
    display: block;
    width: 100%;
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.SongBtn p{
    margin: 0;
    font-weight: 400;
}
.SongBtn img{
    width: 36px;
    height: 36px;
}
.SongBtn span p:last-child,
.SongBtn p:last-child{
    color: rgb(179, 179, 179);
}

@media screen and (max-width: 640px) {
    .PlaylistSongs{
        padding: 0px 16px;
    }
    .SongBtn{
        padding: 0;
    }
}