.PlaylistCardSBox{
    position: relative;
}
.PlaylistCardS{
    position: relative;
    height: 80px;
    background-color: hsla(0,0%,100%,.1);
    border-radius: 4px;
    -webkit-transition: background-color .3s ease;
    display: grid;
    grid-template-columns: 80px 1fr;
}
.ImgBox{
    width: 80px;
    height: 80px;
}
.ImgBox img{
    width: 100%;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
}
.Title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    color: #fff;
}
.Title p {
    -webkit-line-clamp: 2;
    word-break: break-word;
}
.IconBox{
    display: inline-flex;
    opacity: 0;
    transition: all .3s ease;
    box-shadow: 0 8px 8px rgb(0 0 0 / 30%);
    z-index: 2;
    border-radius: 500px;
    position: absolute;
    right: 16px;
    top: calc(50% - 20px);
}
.IconBox div{
    background-color: #f96b06;
    height: 40px;
    width: 40px;
}
.IconBox button{
    height: 40px;
    width: 40px;
    -webkit-transition: -webkit-transform 33ms cubic-bezier(.3,0,0,1);
    transition: -webkit-transform 33ms cubic-bezier(.3,0,0,1);
    transition: transform 33ms cubic-bezier(.3,0,0,1);
    transition: transform 33ms cubic-bezier(.3,0,0,1),-webkit-transform 33ms cubic-bezier(.3,0,0,1);
}
.ActiveIconBox{
    opacity: 1;
}
.IconBox button svg{
    fill: #fff !important;
}
.IconBox button:hover{
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
}
.PlaylistCardS:hover {
    background: hsla(0,0%,100%,.2);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
}
.PlaylistCardSBox:hover .IconBox{
    opacity: 1;
}