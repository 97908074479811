.layout{
    min-height: 100vh;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
}
@media screen and (max-width: 640px) {
    .layout{
        grid-template-columns: 1fr;
    }
}
