.SearchCardBox{
    overflow: hidden;
    position: relative;
    width: 100%;
    border: none;
    border-radius: 8px;
    color: white;
}
.SearchCardBox::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}
.SearchCard{
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear,left bottom,left top,from(transparent),to(rgba(0,0,0,.4)));
    background: linear-gradient(0deg,transparent,rgba(0,0,0,.4));
}
.SearchCard img{
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    -webkit-transform: rotate(25deg) translate(18%,-2%);
    transform: rotate(25deg) translate(18%,-2%);}
.SearchCard h2{
    padding: 16px;
    margin: 0;
    top: 0;
    letter-spacing: -.04em;
    overflow-wrap: break-word;
    position: absolute;
}